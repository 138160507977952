import React from "react"
import ShippingCalculator from "../components/ShippingCalculator/ShippingCalculator"
import Utils from "../utils/node.utils"

export function createOverlay(contentMap) {
  return contentMap.overlay.value
}

export function createContentList(contentMap) {
  return [
    {
      markdown: contentMap.markdown.value,
    },
    {
      header: contentMap.shipping_costs_header.value,
      component: (
        <ShippingCalculator
          defaultCode={Utils.GetDefaultCountry()}
          priceLabel={contentMap.shipping_costs_price.value}
          weightLabel={contentMap.shipping_costs_weight.value}
        />
      ),
    },
  ]
}
