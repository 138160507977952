import React, { Component } from "react"
import Select from "../Shared/Form/Select/Select"
import { store as tStore } from "../Store/Store"
import Utils from "../../utils/node.utils"

export default class ShippingCalculator extends Component {
  state = {
    defaultCode: "",
    formValues: {
      country: "",
      shipping_country: "",
    },
    countries: [],
    groups: [],
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.componentCleanup)
    const { defaultCode } = this.props

    this.unsubscribe = tStore.subscribe(this.storeUpdate)

    let formValues = this.state.formValues
    formValues = Utils.MergeFormValues(
      formValues,
      JSON.parse(localStorage.getItem("form-values"))
    )

    let initCode = Utils.GetCountryFromForm(formValues)

    if (!initCode) {
      initCode = defaultCode
      formValues.country = defaultCode
    }

    this.setState({
      defaultCode: initCode,
      formValues: formValues,
    })
    this.storeUpdate()
  }

  componentCleanup = () => {
    Utils.CancelToken(this.countryCancelToken)
    Utils.CancelToken(this.shippingPriceCancelToken)
    if (this.unsubscribe) {
      this.unsubscribe()
    }
    const { formValues } = this.state

    if (formValues) {
      localStorage.setItem("form-values", JSON.stringify(formValues))
    }
  }

  componentWillUnmount() {
    this.componentCleanup()
    window.removeEventListener("beforeunload", this.componentCleanup)
  }

  fetchData = () => {
    if (this.needFetchCountries) {
      this.fetchCountries()
    }
    if (this.needFetchGroups) {
      this.fetchShippingGroups()
    }
  }

  storeUpdate = () => {
    const storeState = tStore.getState()
    this.setState(
      {
        t: storeState.t,
      },
      this.fetchData
    )
  }

  fetchCountries = () => {
    const { language } = this.props
    const { t } = this.state
    if (!t) {
      return
    }

    this.countriesLoading = true
    this.countryCancelToken = Utils.FetchCountries(
      language,
      t,
      this.setCountries
    )
  }

  setCountries = countries => {
    this.setState({
      countries: countries,
    })
    this.countriesLoading = false
    this.countriesLoaded = true
  }

  needFetchCountries = () => {
    return !this.countriesLoaded && !this.countriesLoading
  }

  fetchShippingGroups = () => {
    const { t, formValues } = this.state
    if (!t) {
      return
    }

    this.groupsLoading = true
    this.shippingPriceCancelToken = Utils.FetchShippingPrice(
      Utils.GetCountryFromForm(formValues),
      t,
      this.setShippingGroups
    )
  }

  setShippingGroups = data => {
    this.setState({
      groups: Utils.GetLowestShippingGroup(data),
    })

    this.groupsLoading = false
    this.groupsLoaded = true
  }

  needFetchGroups = () => {
    return !this.groupsLoaded && !this.groupsLoading
  }

  onChange = value => {
    const { formValues } = this.state

    if (formValues.shipping_country && formValues.shipping_country !== "") {
      formValues.shipping_country = value
    } else if (formValues.country && formValues.country !== "") {
      formValues.country = value
    }

    this.setState(
      {
        formValues: formValues,
      },
      this.fetchShippingGroups
    )
  }

  createShippingTable(group, i) {
    return (
      <div key={i} className="row cart-item">
        <div className="col-6">
          {group.min_weight}g-{group.max_weight}g
        </div>
        <div className="col-6 text-right">
          {Utils.GetPriceString(group.price)}
        </div>
      </div>
    )
  }

  render() {
    const { priceLabel, weightLabel } = this.props
    let { groups } = this.state
    const { countries, defaultCode } = this.state

    if (!groups) {
      groups = []
    }

    const options = countries.map(item => {
      let name

      if (item.info && item.info.length !== 0) {
        name = item.info[0].name
      }
      return {
        code: item.code,
        name: name,
      }
    })

    return (
      <div className="">
        <Select
          name="country_select"
          label="Country"
          options={options}
          defaultValue={defaultCode}
          containerClasses="form-group"
          labelClasses="text-uppercase"
          selectClasses="form-control"
          optionClasses=""
          onChange={this.onChange}
        />
        <div className="container">
          <div className="row font-weight-bold cart-item">
            <div className="col-6">{weightLabel}</div>
            <div className="col-6 text-right">{priceLabel}</div>
          </div>
          {groups.sort(compare).map((item, i) => {
            return this.createShippingTable(item, i)
          })}
        </div>
      </div>
    )
  }
}

ShippingCalculator.defaultProps = {
  defaultCode: "",
  priceLabel: "",
  weightLabel: "",
}

function compare(a, b) {
  if (a.min_weight < b.min_weight) {
    return -1
  }
  if (a.min_weight > b.min_weight) {
    return 1
  }
  return 0
}
