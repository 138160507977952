import React, { Component } from "react"

export default class Select extends Component {
  state = {
    value: "",
    defaultValue: "",
  }

  componentDidMount() {
    const { defaultValue } = this.props

    if (defaultValue) {
      this.setValue(defaultValue, true)
    }
  }

  componentDidUpdate() {
    const { defaultValue } = this.props

    if (!defaultValue) {
      return
    }

    if (defaultValue !== this.state.defaultValue) {
      this.setState({
        value: defaultValue,
        defaultValue: defaultValue,
      })
    }
  }

  componentWillUnmount() {}

  onChange = event => {
    this.setValue(event.target.value)
  }

  setValue = (value, isDefault) => {
    const { onChange } = this.props
    this.setState({
      value: value,
    })
    if (isDefault) {
      this.setState({
        defaultValue: value,
      })
    }
    if (onChange) {
      onChange(value)
    }
  }

  render() {
    const {
      name,
      label,
      options,
      labelClasses,
      containerClasses,
      selectClasses,
      optionClasses,
    } = this.props
    const { value } = this.state
    return (
      <div className={containerClasses}>
        <label className={labelClasses} htmlFor={name}>
          {label}
        </label>
        <select
          id={name}
          name={name}
          value={value}
          className={selectClasses}
          onChange={this.onChange}
        >
          {options.map(item => {
            return (
              <option
                className={optionClasses}
                value={item.code}
                key={item.code}
              >
                {item.name}
              </option>
            )
          })}
        </select>
      </div>
    )
  }
}

Select.defaultProps = {
  name: "",
  label: "Options",
  options: [],
  containerClasses: "",
  labelClasses: "",
  selectClasses: "",
  optionClasses: "",
}
